const Langs = {
  giao_tan_nha: { vi: "Giao tận nhà", en: "Home delivery" },
  den_noi_lay: { vi: "Đến nơi lấy", en: "Pick up" },
  khac: { vi: "Khác", en: "Other" },
  luong_thuc: { vi: "Lương thực, thực phẩm", en: "Food" },
  nhu_yeu_pham: { vi: "Nhu yếu phẩm", en: "Necessaries" },
  vat_dung_y_te: { vi: "Vật dụng y tế", en: "Medical supplies" },
  do_dung_hoc_tap: {
    vi: "Đồ dùng học tập, đồ chơi",
    en: "School supplies & Toys",
  },
  hien_kim: { vi: "Hiện kim", en: "Cash" },
  dang_bai_thanh_cong: {
    vi: "Đăng bài thành công!",
    en: "Your post was shared.",
  },
  dang_bai_that_bai: {
    vi: "Đăng bài thất bại. Vui lòng thử lại sau!",
    en: "Failed to post! Please try again.",
  },
  toan_quoc: { vi: "Toàn quốc", en: "Nationwide " },
  danh_muc_tin_dang: { vi: "Danh mục tin đăng", en: "Post categories " },
  noi_dung_chi_tiet: { vi: "Nội dung chi tiết", en: "Details" },
  tieu_de: { vi: "Tiêu đề ", en: "Title" },
  danh_muc_cho_tang: { vi: "Danh mục Cho tặng", en: "Donation" },
  hinh_thuc_ho_tro: { vi: "Hình thức hỗ trợ", en: "Types of support" },
  mo_ta: { vi: "Mô tả", en: "Description" },
  thong_tin_lien_he: { vi: "Thông tin liên hệ", en: "Contact information" },
  dung_thong_tin_da_dang_ky: {
    vi: "Dùng thông tin đã đăng ký",
    en: "Use your registered information",
  },
  yeu_cau_ho_tro: { vi: "Yêu cầu hỗ trợ", en: "Ask for support" },
  ten_nguoi_lien_he: { vi: "Tên người liên hệ", en: "Contact name" },
  ten_cua_ban: { vi: "Tên của bạn", en: "Your name" },
  dia_chi: { vi: "Địa chỉ", en: "Address" },
  dien_thoai_lien_he: { vi: "Điện thoại liên hệ", en: "Phone number" },
  email_lien_he: { vi: "Email liên hệ", en: "Email" },
  dang_tin: { vi: "Đăng tin", en: "Post" },
  thong_tin_dia_chi: { vi: "Thông tin địa chỉ", en: "Address" },
  tinh_thanh: { vi: "Tỉnh/Thành", en: "City" },
  quan_huyen: { vi: "Quận/Huyện", en: "District" },
  phuong_xa: { vi: "Phường/Xã", en: "Ward" },
  so_nha_ten_duong: { vi: "Số nhà, tên đường", en: "No. and Street" },
  hoan_tat: { vi: "Hoàn tất", en: "Finish" },
  tat_ca: { vi: "Tất cả", en: "All" },
  loai_hinh_van_chuyen: {
    vi: "Loại hình vận chuyển",
    en: "Types of transportation",
  },
  xe_may: { vi: "Xe máy", en: "Motorbike" },
  xe_oto: { vi: "Xe oto", en: "Car" },
  xe_ba_gac: { vi: "Xe ba gác", en: "Cargo tricycle" },
  xe_van_500kg: { vi: "Xe VAN 500kg", en: "500kg Van" },
  xe_van_1000kg: { vi: "Xe VAN 1000kg", en: "1000kg Van" },
  xe_tai_500kg: { vi: "Xe tải 500kg", en: "500kg Truck" },
  xe_tai_1000kg: { vi: "Xe tải 1000kg", en: "1000kg Truck" },
  xe_tai_2000kg: { vi: "Xe tải 2000kg", en: "2000kg Truck" },
  thuc_pham: { vi: "Thực phẩm", en: "Food" },
  hang_tieu_dung: { vi: "Hàng tiêu dùng", en: "Consumer goods" },
  hang_hoa_phong_chong_dich: {
    vi: "Hàng hóa phòng chống dịch",
    en: "Anti-epidemic goods",
  },
  nguyen_vat_lieu_san_xuat: { vi: "Nguyên vật liệu sản xuất", en: "Materials" },
  thong_tin_hang_hoa: { vi: "Thông tin hàng hóa", en: "Cargo Information" },
  xac_minh_thanh_cong: {
    vi: "Xác minh tài khoản thành công",
    en: "Account verification successful",
  },
  xac_minh_that_bai: {
    vi: "Xác minh tài khoản thất bại. Vui lòng thử lại sau!",
    en: "Account verification failed. Please try again!",
  },
  can_dong_y: {
    vi: "Bạn cần đồng ý chịu trách nhiệm về tính chính xác của các thông tin trên",
    en: "You must agree to be responsible for the accuracy of the above information",
  },
  chua_up_hinh_giay_phep: {
    vi: "Bạn cần cung cấp hình ảnh giấy phép thành lập và công nhận điều lệ quỹ từ thiện",
    en: "You must provide photo(s) of the establishment license and recognition of the charity's charter",
  },
  chua_up_hinh_cmnd: {
    vi: "Bạn cần cung cấp 2 hình ảnh CMND/CCCD/Hộ chiếu",
    en: "You must provide 2 photos of your ID/Passport",
  },
  so_luong_tuyen_dung: {
    vi: "Số lượng tuyển dụng",
    en: "Number of recruitments",
  },
  hang_hoa_khac: { vi: "Hàng hóa khác", en: "Others" },
  nganh_nghe: { vi: "Ngành nghề ", en: "Occupation" },
  cong_nhan: { vi: "Công nhân", en: "Employee" },
  tho_ky_thuat: { vi: "Thợ kỹ thuật", en: "Technicians" },
  dich_vu: { vi: "Dịch vụ", en: "Service" },
  chuyen_nganh: { vi: "Chuyên ngành", en: "Major" },
  toan_thoi_gian: { vi: "Toàn thời gian", en: "Full time" },
  ban_thoi_gian: { vi: "Bán thời gian", en: "Part time" },
  thoi_vu: { vi: "Thời vụ", en: "Seasonal" },
  theo_san_pham: {
    vi: "Theo sản phẩm / Làm tại nhà",
    en: "Pieceworker/Work at home",
  },
  lam_theo_ca: { vi: "Làm theo ca", en: "Work on shifts" },
  thuc_tap_du_an: { vi: "Thực tập / Dự án", en: "Internship/ Project" },
  loai_cong_viec: { vi: "Loại công việc", en: "Types of occupation" },
  hinh_thuc_tra_luong: { vi: "Hình thức trả lương", en: "Types of payment" },
  theo_gio: { vi: "Theo giờ", en: "Hourly" },
  theo_ngay: { vi: "Theo ngày", en: "Daily" },
  theo_thang: { vi: "Theo tháng", en: "Monthly" },
  luong_khoan: { vi: "Lương khoán", en: "Contractual " },
  loai_cong_viec_khac: { vi: "Loại công việc khác", en: "Other occupation" },
  nganh_nghe_khac: { vi: "Ngành nghề khác", en: "Other career" },
  do_tuoi_toi_thieu: { vi: "Độ tuổi tối thiểu", en: "Minimum age" },
  gioi_tinh: { vi: "Giới tính", en: "Gender" },
  gioi_tinh_nam: { vi: "Nam", en: "Male" },
  gioi_tinh_nu: { vi: "Nữ", en: "Female" },
  kinh_ngiem: { vi: "Kinh nghiệm", en: "Experience" },
  nam_kinh_ngiem: { vi: "Năm", en: "Year" },
  trinh_do_hoc_van_cao_nhat: {
    vi: "Trình độ học vấn cao nhất",
    en: "Education",
  },
  cap_1: { vi: "Cấp 1", en: "Primary school" },
  cap_2: { vi: "Cấp 2", en: "Secondary school" },
  cap_3: { vi: "Cấp 3", en: "Highschool " },
  trung_cap_nghe: { vi: "Trung cấp / Nghề", en: "Vocational school" },
  cao_dang: { vi: "Cao đẳng", en: "Junior college" },
  dai_hoc: { vi: "Đại học", en: "University" },
  tu_ngay: { vi: "Từ ngày", en: "From day" },
  den_ngay: { vi: "Đến ngày", en: "To day" },
  nam_sinh: { vi: "Năm sinh", en: "Year of birth" },
  trinh_do_hoc_van_khac: {
    vi: "Trình độ học vấn khác",
    en: "Other educational qualifications",
  },
  chung_chi_ky_nang: { vi: "Chứng chỉ / Kỹ năng", en: "Certificate/Skills" },
  loai_cho_o: { vi: "Loại chỗ ở", en: "Types of accomodation" },
  phong_tro: { vi: "Phòng trọ", en: "Motel room" },
  can_ho_chung_cu: { vi: "Căn hộ / Chung cư", en: "Apartment" },
  nha_o: { vi: "Nhà ở", en: "House" },
  dat_o: { vi: "Đất", en: "Land" },
  van_phong_mat_bang: {
    vi: "Văn phòng / Mặt bằng kinh doanh",
    en: "Office/Business premises",
  },
  khu_vuc_cho_o: { vi: "Khu vực chỗ ở", en: "Living area" },
  ten_toa_nha: {
    vi: "Tên tòa nhà / Khu dân cư / Dự án",
    en: "Building/Residential area/Project",
  },
  dien_tich: { vi: "Diện tích", en: "Area" },
  tinh_trang_noi_that: { vi: "Tình trạng nội thất", en: "Furniture status" },
  thong_tin_them: { vi: "Thông tin thêm", en: "Other Information" },
  yeu_cau_khac: { vi: "Yêu cầu khác", en: "Other request" },
  thit_ca_hai_san: { vi: "Thịt, cá, hải sản", en: "Meat & Seafood" },
  rau_cu_trai_cay: { vi: "Rau, củ, trái cây", en: "Fruit & Vegetables" },
  do_uong_cac_loai: { vi: "Đồ uống các loại", en: "Drinks" },
  banh_keo_cac_loai: { vi: "Bánh kẹo các loại", en: "Sweet" },
  mi_chao_pho_bun: { vi: "Mì, cháo, phở, bún", en: "Instant & Canned" },
  dau_an_gia_vi: { vi: "Dầu ăn, gia vị", en: "Sauce & Seasonings" },
  gao_bot_do_kho: { vi: "Gạo, bột, đồ khô", en: "Rice & Dried food" },
  do_mat_dong_lanh: { vi: "Đồ mát, đông lạnh", en: "Frozen food" },
  do_dung_gia_dinh: { vi: "Đồ dùng gia đình", en: "Home & Living" },
  danh_muc_ho_tro: { vi: "Danh mục hỗ trợ", en: "Support categories" },
  dang_tin_30_ngay: {
    vi: "Chúng tôi cho phép bạn giới hạn thời gian hiển thị tin. Tin quá thời hạn (tối đa 30 ngày) sẽ được tự động gỡ bỏ trên thông tin cộng đồng.",
    en: "We allow you to limit the time your posts are displayed.Ocerdue posts (up to 30 days) will be automatically removed from the community information.",
  },
  dang_tin_7_ngay: {
    vi: "Tin của bạn chỉ có hiệu lực trong 7 ngày. Sau 7 ngày, tin sẽ bị gỡ khỏi danh sách và bản đồ.",
    en: "Your post will expire in 7 days. After 7 days, post will be hidden from public news and map.",
  },
  ngay_ngung_hien_thi_tin: {
    vi: "Ngày ngưng hiển thị tin",
    en: "Post expiration date",
  },
  thong_tin_to_chuc: {
    vi: "Thông tin tổ chức",
    en: "Organization information",
  },
  dia_chi_tru_so_chinh: {
    vi: "Địa chỉ trụ sở chính",
    en: "Head office address",
  },
  dia_chi_chi_nhanh: { vi: "Địa chỉ chi nhánh", en: "Branch address" },
  giay_phep_thanh_lap: {
    vi: "Giấy phép thành lập và công nhận điều lệ quỹ từ thiện (tối đa 10 hình)",
    en: " The establishment license and recognition of the charity's charter (maximum 10 images)",
  },
  hinh_la_bat_buoc: { vi: "Hình là bắt buộc", en: "Photos are compulsory" },
  linh_vuc_hoat_dong: { vi: "Lĩnh vực hoạt động", en: "Field of activity" },
  du_an_xa_hoi: { vi: "Dự án xã hội", en: "Social project" },
  y_te_suc_khoe: { vi: "Y tế & sức khỏe", en: "Medical-Health" },
  cuu_tro_khan_cap: { vi: "Cứu trợ khẩn cấp", en: "Emergency" },
  linh_vuc_khac: { vi: "Lĩnh vực khác", en: "Other" },
  thong_tin_nguoi_dai_dien_tai_khoan: {
    vi: "Thông tin người đại diện tài khoản",
    en: "Account representative information",
  },
  thong_tin_tai_khoan: { vi: "Thông tin tài khoản", en: "Account information" },
  ten_nguoi_dai_dien: { vi: "Tên người đại diện", en: "Representative name" },
  ho_va_ten: { vi: "Họ và tên", en: "Full name" },
  ngay_thang_nam_sinh: { vi: "Ngày/tháng/năm sinh", en: "Date of birth" },
  noi_dung: { vi: "Nội dung", en: "Content" },
  co_the_chon_nhieu: { vi: "Có thể chọn nhiều", en: "Multiple choice" },
  hinh_thuc_ho_tro_khac: { vi: "Hình thức hỗ trợ khác", en: "Other support" },
  thong_bao: { vi: "Thông báo", en: "Notification" },
  quoc_tich: { vi: "Quốc tịch", en: "Nationality" },
  so_CMND: { vi: "Số CMND/CCCD/Hộ chiếu", en: "ID/Passport number" },
  noi_cap_CMND: {
    vi: "Nơi cấp CMND/CCCD/Hộ chiếu",
    en: "Place of issue ID/Passport",
  },
  hinh_CMND_2_mat: {
    vi: "Hình CMND/CCCD/Hộ chiếu (2 mặt, tối đa 2 hình)",
    en: "Photos of ID/Passport",
  },
  dia_chi_thuong_tru: { vi: "Địa chỉ thường trú", en: "Permanent address" },
  noi_o_hien_nay: { vi: "Nơi ở hiện nay", en: "Temporary address" },
  so_dien_thoai: { vi: "Số điện thoại", en: "Phone number" },
  co_the_khac_sdt_dang_ky: {
    vi: "có thể khác sđt đăng ký tài khoản",
    en: "can be different from the account registered phone number",
  },
  email: { vi: "Email", en: "Email" },
  chiu_trach_nhiem_tinh_chinh_xac: {
    vi: "Tôi hoàn toàn chịu trách nhiệm về tính chính xác của các thông tin nêu trên.",
    en: "I take full responsibility for the accuracy of the above information.",
  },
  xac_minh_tai_khoan: { vi: "Xác minh tài khoản", en: "Verify account" },
  la_bat_buoc: { vi: "là bắt buộc", en: "is required." },
  cap_nhat_tin: { vi: "Cập nhật tin", en: "Update posts" },
  cap_nhat_thanh_cong: {
    vi: "Cập nhật thành công!",
    en: "Successfully updated.",
  },
  cap_nhat_that_bai: {
    vi: "Cập nhật thất bại. Vui lòng thử lại sau!",
    en: "Update failed. Please try again!",
  },
  danh_muc_ho_tro_khac: { vi: "Danh mục hỗ trợ khác", en: "Other supports" },
  day_la_truong_bat_buoc: {
    vi: "Đây là trường bắt buộc",
    en: "Field is required",
  },
  xac_nhan_cap_nhat_trang_thai_bai_viet: {
    vi: "Xác nhận cập nhật trạng thái bài viết?",
    en: "Want to confirm your update?",
  },
  cap_nhat_trang_thai_bai_viet_thanh_cong: {
    vi: "cập nhật trạng thái bài viết thành công",
    en: "Update successful",
  },
  cap_nhat_trang_thai_bai_viet_that_bai: {
    vi: "cập nhật trạng thái bài viết thất bại",
    en: "Update failed",
  },
  dong_y: { vi: "Đồng ý", en: "Agree" },
  huy_bo: { vi: "Hủy bỏ", en: "Cancel" },
  tam_dung_bai_viet: { vi: "Tạm dừng tin", en: "Hide post" },
  kich_hoat_bai_viet: { vi: "Kích hoạt tin", en: "Unhide post" },
  dang_ky_dang_nhap_de_xem_thong_tin: {
    vi: "Đăng ký/Đăng nhập để xem thông tin",
    en: "Sign up/ Sign in",
  },
  khu_vuc: { vi: "Khu vực", en: "Area" },
  loai_hinh_van_chuyen_khac: {
    vi: "Loại hình vận chuyển khác",
    en: "Other types of transportation",
  },
  hinh_thuc_tra_luong_khac: {
    vi: "Hình thức trả lương khác",
    en: "Other payment methods",
  },
  loai_cho_o_khac: { vi: "Loại chỗ ở khác", en: "Other accommodation" },
  ban_do: { vi: "Bản đồ", en: "Show on map" },
  gioi_tinh_khong_yeu_cau: { vi: "Không yêu cầu", en: "Not required" },
  nam_sinh_tu: { vi: "Năm sinh từ", en: "From year" },
  nam_sinh_den: { vi: "Năm sinh đến", en: "To year" },
  sap_xep_theo: { vi: "Sắp xếp theo", en: "Sort by" },
  tin_moi_nhat: { vi: "Tin mới nhất", en: "Latest" },
  tin_cu_nhat: { vi: "Tin cũ nhất", en: "Oldest" },
  dang_boi: { vi: "Đăng bởi", en: "Post by" },
  ca_nhan: { vi: "Cá nhân", en: "Personal" },
  to_chuc: { vi: "Tổ chức", en: "Organization" },
  manh_thuong_quan: { vi: "Mạnh thường quân", en: "Sponsor" },
  dien_tich_tu: { vi: "Diện tích từ", en: "From" },
  dien_tich_den: { vi: "Diện tích đến", en: "To" },
  dang_hien_thi: { vi: "Đang hiển thị", en: "Displaying " },
  tam_ngung: { vi: "Tạm ngưng", en: "Pausing" },
  tin_het_han: { vi: "Tin hết hạn", en: "Expired" },
  chua_co_tin_moi: { vi: "Chưa có tin mới", en: "No posts yet" },
  khong_tim_thay_ket_qua: {
    vi: "Rất tiếc, chúng tôi không tìm thấy kết quả nào phù hợp",
    en: "Sorry, we couldn't find any matches",
  },
  tim_hieu_chi_tiet_danh_muc_tin_dang: {
    vi: "Tìm hiểu chi tiết danh mục tin đăng",
    en: "Find out the details of post categories",
  },
  tim_kiem: { vi: "Tìm kiếm", en: "Searching" },
  tai_anh_len: { vi: "Tải ảnh lên", en: "Upload photos" },
  toi_da_1_anh: { vi: "Tối đa 1 ảnh", en: "Maximum one photo" },
  de_hoat_dong_tu_thien_duoc_minh_bach: {
    vi: "Để hoạt động từ thiện được minh bạch, chúng tôi sẽ công khai thông tin liên hệ của bạn.",
    en: "For transparency, your contact information is shared publicly.",
  },
  neu_ban_muon_dung_thong_tin_ca_nhan: {
    vi: "Nếu bạn muốn dùng thông tin cá nhân để đăng ký, vui lòng nhấn chọn",
    en: "If you want to use registered personal information, please tick",
  },
  thong_tin_da_dang_ky: {
    vi: "Thông tin đã đăng ký.",
    en: "Registered information",
  },
  neu_ban_muon_tim_tinh_nguyen_vien: {
    vi: "Nếu bạn muốn tìm tình nguyện viên hoặc mạnh thường quân giúp đỡ, vui lòng chọn",
    en: "If you want to find volunteers or sponsors, please tick",
  },
  tham_gia_ho_tro: { vi: "Tham gia hỗ trợ", en: "I want to volunteer" },
  dang_ky_ho_tro: { vi: "Đăng ký hỗ trợ", en: "Register to support" },
  chia_se_thong_tin_ve_ban: {
    vi: "Chia sẻ thông tin về bạn",
    en: "Share your Information",
  },
  chua_co_thong_tin: { vi: "Chưa có thông tin", en: "No information " },
  dang_ky_ho_tro_thanh_cong: {
    vi: "Đăng ký hỗ trợ thành công",
    en: "Register successful!",
  },
  dang_ky_ho_tro_that_bai: {
    vi: "Đăng ký hỗ trợ thất bại",
    en: "Register failed!",
  },
  hinh_anh_la_bat_buoc: { vi: "Hình ảnh là bắt buộc", en: "Image is required" },
  quen_mat_khau: { vi: "Quên mật khẩu?", en: "Forgot password?" },
  doi_mat_khau: { vi: "Đổi mật khẩu", en: "Change password" },
  nhap_so_dien_thoai_da_dang_ky: {
    vi: "Nhập số điện thoại đã đăng ký để đặt lại mật khẩu",
    en: "Please enter your registered phone number to reset your password",
  },
  nhan_ma_xac_nhan: { vi: "Nhận mã xác nhận", en: "Get a confirmation code" },
  that_bai_vui_long_thu_lai_sau: {
    vi: "thất bại. Vui lòng thử lại sau.",
    en: "Failed! Please try again",
  },
  nhap_ma_xac_nhan: {
    vi: "Nhập mã xác nhận",
    en: "Enter your vertification code",
  },
  vui_long_nhap_ma_xac_nhan_da_gui_qua_email: {
    vi: "Vui lòng nhập mã xác nhận đã gửi qua email",
    en: "Please enter your vertification code sent to your email",
  },
  cua_ban: { vi: "của bạn", en: "Vertification code" },
  ma_xac_nhan: { vi: "Mã xác nhận", en: "Verify" },
  xac_nhan: { vi: "Xác nhận", en: "Confirm" },
  gui_lai_ma: { vi: "Gửi lại mã", en: "Resend code" },
  so_dien_thoai_chua_duoc_dang_ky_trong_he_thong: {
    vi: "Số điện thoại chưa được đăng ký trong hệ thống",
    en: "The phone number is not registerd in the system",
  },
  chua_co_danh_sach_yeu_thuong: {
    vi: "Chưa có danh sách yêu thương",
    en: "Cherishing list is not available.",
  },
  doi_mat_khau_thanh_cong: {
    vi: "Đổi mật khẩu thành công",
    en: "Changed password successfully",
  },
  tro_ve: { vi: "Trở về", en: "Back" },
  danh_sach_yeu_thuong: { vi: "Danh sách yêu thương", en: "Cherishing list" },
  xem_ngay_nhung_tai_khoan: {
    vi: "Xem ngay những tài khoản đã trao yêu thương đến",
    en: "See now who has cherished to the",
  },
  chao_mung_ban_den_voi: {
    vi: "Chào mừng bạn đến với",
    en: "Welcome to",
  },
  ket_noi_yeu_thuong: { vi: "Kết nối yêu thương", en: "Ket noi yeu thuong" },
  cong_dong_tu_thien_uy_tin_minh_bach: {
    vi: "Cộng đồng từ thiện uy tín, minh bạch",
    en: "The prestigious and transparent charity community ",
  },
  dang_nhap_hoac_dang_ky_tai_khoan: {
    vi: "Đăng nhập hoặc Đăng ký tài khoản",
    en: "Sign in or Create new account",
  },
  luc_khac: { vi: "Lúc khác", en: "Next time" },
  gui_yeu_cau_thanh_cong: {
    vi: "Gửi yêu cầu thành công",
    en: "Send request successfully",
  },
  yeu_cau_cua_ban_da_gui_thanh_cong: {
    vi: "Yêu cầu của bạn đã gửi thành công. Người tổ chức sẽ liên hệ với bạn trong vài ngày tới hoặc bạn có thể liên hệ trực tiếp với người tổ chức.",
    en: "Your request has been sent successfully. The organizer will contact you in the next few days or you can contact the organizer directly.",
  },
  gui_lai_ma_thanh_cong: {
    vi: "Gửi lại mã thành công",
    en: "Code sent successfully",
  },
  so_tim: { vi: "Số tim", en: "Number of hearts" },
  tim_quanh_toi: { vi: "Tìm quanh tôi", en: "Around me" },
  bo_loc: { vi: "Bộ lọc", en: "Filter" },
  ten_to_chuc_manh_thuong_quan: {
    vi: "Tên tổ chức / Mạnh thường quân",
    en: "Name of Organization/Sponsor",
  },
  do_uy_tin_cao: { vi: "Độ uy tín cao", en: "High prestige" },
  do_uy_tin_thap: { vi: "Độ uy tín thấp", en: "Low prestige" },
  ve_toi: { vi: "Về tôi", en: "About me" },
  tin_cua_toi: { vi: "Tin của tôi", en: "My posts" },
  chinh_sua_thong_tin: {
    vi: "Chỉnh sửa thông tin",
    en: "Edit profile information",
  },
  ngon_ngu: { vi: "Ngôn ngữ", en: "Language" },
  tieng_viet: { vi: "Tiếng Việt", en: "Vietnamese" },
  tieng_anh: { vi: "Tiếng Anh", en: "English" },
  chua_cap_nhat: { vi: "Chưa cập nhật", en: "Not updated" },
  dang_xuat: { vi: "Đăng xuất", en: "Log out" },
  cap_nhat_thong_tin: {
    vi: "Cập nhật thông tin",
    en: "Update profile information",
  },
  ten_to_chuc: { vi: "Tên tổ chức", en: "Name of Organization" },
  so_luong_thanh_vien: { vi: "Số lượng thành viên", en: "Number of members" },
  nguoi_dai_dien: { vi: "Người đại diện", en: "Representative" },
  email_khong_dung_dinh_dang: {
    vi: "Email không đúng định dạng",
    en: "Email is not in correct format.",
  },
  ngay_sinh_khong_hop_le: {
    vi: "Ngày sinh không hợp lệ",
    en: "The birth date entered is invalid.",
  },
  khong_chon_ngay_trong_qua_khu: {
    vi: "Không chọn ngày trong quá khứ",
    en: "Do not enter past dates.",
  },
  tu_gioi_thieu: {
    vi: "Tự giới thiệu (tối đa 250 kí tự)",
    en: "Introduce about yourself (Maximum 150 characters)",
  },
  duong_dan_link_fb: {
    vi: "Đường dẫn (link) tài khoản Facebook",
    en: "Link Facebook",
  },
  duong_dan_link_web: { vi: "Đường dẫn (link) Website", en: "Link website" },
  hoan_thanh: { vi: "Hoàn thành", en: "Finish" },
  mat_khau_hien_tai: { vi: "Mật khẩu hiện tại", en: "Current password" },
  mat_khau_moi: { vi: "Mật khẩu mới", en: "New password" },
  xac_nhan_mat_khau_moi: {
    vi: "Xác nhận mật khẩu mới",
    en: "Re-type new password",
  },
  dang_xuat_thanh_cong: {
    vi: "Đăng xuất thành công!",
    en: "Log out successfull!",
  },
  dang_xuat_that_bai: {
    vi: "Đăng xuất thất bại. Vui lòng thử lại sau!",
    en: "Log out failed. Please try again!",
  },
  cap_nhat_mat_khau_moi_thanh_cong: {
    vi: "Cập nhật mật khẩu mới thành công!",
    en: "Password changed successfully!",
  },
  mat_khau_hien_tai_khong_chinh_xac: {
    vi: "Mật khẩu hiện tại của bạn không chính xác!",
    en: "Password is incorrect!",
  },
  cap_nhat_mat_khau_moi_that_bai: {
    vi: "Cập nhật mật khẩu mới thất bại. Vui lòng thử lại sau!",
    en: "Change password failed. Please try again!",
  },
  trang_ca_nhan: { vi: "Trang cá nhân", en: "Personal page" },
  ban_co_chac_chan_muon_dang_xuat: {
    vi: "Bạn có chắc chắn muốn đăng xuất?",
    en: "Log out for your account?",
  },
  chinh_sua_tin: { vi: "Chỉnh sửa tin", en: "Edit post" },
  ban_muon_dung_tin: {
    vi: "Bạn muốn dừng tin?",
    en: "Do you want to pause post?",
  },
  ban_muon_kich_hoat_tin: {
    vi: "Bạn muốn kích hoạt tin?",
    en: "Do you want to active post?",
  },
  xoa_bo_loc: { vi: "Xóa bộ lọc", en: "Clear filter" },
  trang_chu: { vi: "Trang chủ", en: "Home" },
  cong_dong: { vi: "Cộng đồng", en: "Community" },
  cho_tang: { vi: "Cho tặng", en: "Donate" },
  van_chuyen: { vi: "Vận chuyển", en: "Transports" },
  cong_viec: { vi: "Công việc", en: "Jobs" },
  cho_o: { vi: "Chỗ ở", en: "Accommodation" },
  cho_yeu_thuong: { vi: "Chợ yêu thương", en: "Cherish market" },
  can_giup_do: { vi: "Cần giúp đỡ", en: "Need help" },
  tim_viec: { vi: "Tìm việc", en: "Look for a job" },
  tim_nguoi: { vi: "Tìm người", en: "Recruitment" },
  tim_nha_cung_cap: { vi: "Tìm nhà cung cấp", en: "Find a supplier" },
  nha_cung_cap: { vi: "Nhà cung cấp", en: "Supplier" },
  tim_nguoi_van_chuyen: { vi: "Tìm người vận chuyển", en: "Find a carrier" },
  nhan_van_chuyen: { vi: "Nhận vận chuyển", en: "Carrier" },
  tim_cho_o: { vi: "Tìm chỗ ở", en: "Find accommodation" },
  ho_tro_cho_o: { vi: "Hỗ trợ chỗ ở", en: "Accommodation support" },
  chon_danh_muc_tin_dang: {
    vi: "Chọn danh mục tin đăng",
    en: "Choose posts categories",
  },
  tieu_de_qua_gioi_han: {
    vi: "Tiêu đề quá giới hạn 256 ký tự",
    en: "The title character limit is 256",
  },
  so_dien_thoai_khong_dung_dinh_dang: {
    vi: "Số điện thoại không đúng định dạng",
    en: "Phone number is not valid",
  },
  ngay_ngung_hien_thi_tin_khong_qua_30_ngay: {
    vi: "Ngày ngừng hiển thị tin không được quá 30 ngày",
    en: "Post discontinuation date is not over 30 days",
  },
  ngay_ngung_hien_thi_tin_khong_hop_le: {
    vi: "Ngày ngừng hiển thị tin không hợp lệ",
    en: "Post discontinuation date is not valid",
  },
  nam_sinh_tu_khong_hop_le: {
    vi: "Năm sinh từ không hợp lệ",
    en: "From year is not valid",
  },
  nam_sinh_den_khong_hop_le: {
    vi: "Năm sinh đến không hợp lệ",
    en: "To year is not valid",
  },
  mat_khau_phai_co_it_nhat: {
    vi: "Mật khẩu phải có ít nhất 8 ký tự",
    en: "At least 8 characters",
  },
  mat_khau_khong_duoc_qua: {
    vi: "Mật khẩu không được quá 32 ký tự",
    en: "Limit 32 characters",
  },
  mat_khau: { vi: "Mật khẩu", en: "Password" },
  dang_nhap_thanh_cong: {
    vi: "Đăng nhập thành công!",
    en: "Log in successful",
  },
  dang_nhap_that_bai: {
    vi: "Đăng nhập thất bại. Vui lòng thử lại sau!",
    en: "Log in failed. Please try again!",
  },
  so_dien_thoai_hoac_mat_khau_khong_chinh_xac: {
    vi: "Số điện thoại hoặc mật khẩu không chính xác",
    en: "Phone number or password not correct",
  },
  hanh_trinh_cua_nhung_dieu_binh_thuong: {
    vi: "Hành trình của những điều bình thường trở thành phi thường",
    en: "",
  },
  dang_nhap: { vi: "Đăng nhập", en: "Log in" },
  mat_khau_xac_nhan_khong_trung_khop: {
    vi: "Mật khẩu xác nhận không trùng khớp",
    en: "You must enter the same password twice in order to confirm it",
  },
  xac_nhan_mat_khau: { vi: "Xác nhận mật khẩu", en: "Confirm password" },
  ban_can_dong_y_voi_dieu_khoan: {
    vi: "Bạn cần đồng ý với Điều khoản chương trình và Chính sách dữ liệu để tạo tài khoản",
    en: "You need to agree to the Terms of Program and Data Policy to create an account",
  },
  dang_ky_tai_khoan_thanh_cong: {
    vi: "Đăng ký tài khoản thành công!",
    en: "Congratulations, your account has been successfully created!",
  },
  email_da_duoc_dang_ky: {
    vi: "Email này đã được đăng ký. Vui lòng sử dụng Email khác.",
    en: "This email is already registered. Please use another email.",
  },
  so_dien_thoai_da_duoc_dang_ky: {
    vi: "Số điện thoại này đã được đăng ký. Vui lòng sử dụng số điện thoại khác.",
    en: "This phone number is already registered. Please use another phone number.",
  },
  dang_ky_tai_khoan_that_bai: {
    vi: "Đăng ký tài khoản thất bại. Vui lòng thử lại sau!",
    en: "Account registration failed. Please try again later!",
  },
  ban_la: { vi: "Bạn là", en: "You are" },
  so_dien_thoai_dang_nhap: {
    vi: "Số điện thoại đăng nhập",
    en: "Phone number login",
  },
  toi_dong_y_voi: { vi: "Tôi đồng ý với", en: "I agree with" },
  dieu_khoan_chuong_trinh: {
    vi: "Điều khoản chương trình",
    en: "Terms of Program",
  },
  chinh_sach_du_lieu: { vi: "Chính sách dữ liệu", en: "Data policy" },
  cua_he_thong: { vi: "của hệ thống.", en: "of system." },
  xac_nhan_thong_tin: { vi: "Xác nhận thông tin", en: "Confirm information" },
  vui_long_doi_mat_khau_moi: {
    vi: "Vui lòng đổi mật khẩu khác với mật khẩu cũ!",
    en: "New password cannot be the same as your old password.",
  },
  cap_nhat: { vi: "Cập nhật", en: "Update" },
  hinh_nen: { vi: "hình nền", en: "Cover photo" },
  anh_dai_dien: { vi: "ảnh đại diện", en: "Avatar" },
  thanh_cong: { vi: "thành công!", en: "Successful!" },
  that_bai: {
    vi: "thất bại. Vui lòng thử lại sau!",
    en: "failed. Please try again!",
  },
  mat_khau_cu_phai_co_it_nhat: {
    vi: "Mật khẩu cũ phải có ít nhất 8 ký tự",
    en: "The old password must be at least 8 characters.",
  },
  mat_khau_cu_khong_duoc_qua: {
    vi: "Mật khẩu cũ không được quá 32 ký tự",
    en: "The old password must be at least 32 characters.",
  },
  mat_khau_cu: { vi: "Mật khẩu cũ", en: "Old password" },
  chia_se_bai_viet: { vi: "Chia sẻ bài viết", en: "Share post" },
  thong_tin: { vi: "Thông tin", en: "Information" },
  lien_he: { vi: "Liên hệ", en: "Contact" },
  dang_ky_tai_khoan: { vi: "Đăng ký tài khoản", en: "Create an account" },
  danh_sach_cong_dong: { vi: "Danh sách cộng đồng", en: "Community list" },
  ly_do_bao_cao: { vi: "Lý do báo cáo", en: "Reasons for reporting" },
  bao_cao_vi_pham: { vi: "Báo cáo vi phạm", en: "Report" },
  ly_do_qua_gioi_han: {
    vi: "Lý do báo cáo quá giới hạn 256 ký tự",
    en: "The reason for reporting exceeds the maximum length of 256 characters.",
  },
  copy_link_thanh_cong: {
    vi: "Copy link thành công.",
    en: "Copy link successfully.",
  },
  bao_cao_vi_pham_thanh_cong: {
    vi: "Báo cáo vi phạm thành công!",
    en: "Report successfully!",
  },
  bao_cao_vi_pham_that_bai: {
    vi: "Báo cáo vi phạm thất bại. Vui lòng thử lại sau!",
    en: "Report failed. Please try again!",
  },
  cong_ty_tnhh_hoan_cau: {
    vi: "CÔNG TY TNHH HC HOÀN CẦU",
    en: "HC HOAN CAU CO., LTD",
  },
  dia_chi_footer: {
    vi: "Địa chỉ: 24 Bis Đông Du, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh, Việt Nam.",
    en: "Address: 24 Bis Dong Du Street, Ben Nghe Ward, District 1, Ho Chi Minh City, Vietnam.",
  },
  mst_footer: {
    vi: "MST: 0312504771 - Cấp ngày 07/03/2016",
    en: "TIN: 0312504771 - Issued on 07/03/2016",
  },
  noi_cap_footer: {
    vi: "Nơi cấp: Phòng Đăng ký kinh doanh - Sở Kế hoạch Và Đầu tư thành phố Hồ Chí Minh",
    en: "Place of issue: Business Registration Office - Department of Planning and Investment of HCMC",
  },
  dien_thoai_footer: {
    vi: "Điện thoại: 0908 298 201",
    en: "Phone: 0908 298 201",
  },
  email_footer: {
    vi: "Email: bienny797@gmail.com",
    en: "Email: bienny797@gmail.com",
  },
  dien_dan_trao_doi: {
    vi: "Diễn đàn trao đổi, chia sẻ hoạt động thiện nguyện và từ thiện",
    en: "Forum to exchange and share volunteering and charitable activities",
  },
  web_chay_thu_ngiem: {
    vi: "Website đang chạy thử nghiệm để chờ cấp giấy phép.",
    en: "The website is under testing for a license.",
  },
  chiu_trach_nhiem_noi_dung: {
    vi: "Chịu trách nhiệm nội dung: Hà Thị Ngọc Ánh",
    en: "Content censor: Ha Thi Ngoc Anh",
  },
  email_footer_chiu_trach_nhiem: {
    vi: "Email hỗ trợ: hotro@weewoo.vn    ",
    en: "Support email: hotro@weewoo.vn",
  },
  sdt_footer_chiu_trach_nhiem: {
    vi: "Điện thoại: 0977 847 876",
    en: "Phone: 0977 847 876",
  },
  don_vi_van_hanh: {
    vi: "Đơn vị vận hành: Công ty TNHH HC Hoàn Cầu",
    en: "Operator: HC Hoan Cau Co., Ltd",
  },
  xoa: { vi: "Xóa", en: "Delete" },
  chon: { vi: "Chọn", en: "Choose" },
  huy: { vi: "Hủy", en: "Cancel" },
  ngay_sinh: { vi: "Ngày sinh", en: "The birth date" },
  danh_sach_to_chuc: { vi: "Danh sách tổ chức", en: "Organization list" },
  danh_sach_manh_thuong_quan: {
    vi: "Danh sách mạnh thường quân",
    en: "Sponsors list",
  },
  lay_thong_tin_tai_khoan_that_bai: {
    vi: "Lấy thông tin thành viên thất bại",
    en: "Failed to get member information",
  },
  xem_them: { vi: "Xem thêm", en: "Load more" },
  cuoi_danh_sach: {
    vi: "Bạn đã xem đến cuối danh sách",
    en: "Nothing more to load",
  },
  nam_sinh_khong_hop_le: {
    vi: "Năm sinh không hợp lệ",
    en: "Year of birth is not valid",
  },
  danh_sach_tin_tuc: {
    vi: "Danh sách Tin tức",
    en: "List of News",
  },
  danh_sach_cho_tang: {
    vi: "Danh sách Cho tặng",
    en: "List of Donation",
  },
  danh_sach_van_chuyen: {
    vi: "Danh sách Vận chuyển",
    en: "List of Transports",
  },
  danh_sach_cong_viec: {
    vi: "Danh sách Công việc",
    en: "List of Jobs",
  },
  danh_sach_cho_o: {
    vi: "Danh sách Chỗ ở",
    en: "List of Accommodation",
  },
  danh_sach_cho_yeu_thuong: {
    vi: "Danh sách Chợ yêu thương",
    en: "List of Cherish market",
  },
  doi_ngon_ngu_thanh_cong: {
    vi: "Đổi ngôn ngữ thành công",
    en: "Language changed successfully",
  },
  tim_hieu_cac_loai_tai_khoan_dang_ky: {
    vi: "Tìm hiểu các loại tài khoản đăng ký",
    en: "About types of accounts on Ket Noi Yeu Thuong",
  },
  vi_tri_cua_toi: {
    vi: "Vị trí của tôi",
    en: "My location",
  },
  doc_tat_ca: {
    vi: "Đọc tất cả",
    en: "Read all",
  },
  thong_bao_chung: {
    vi: "Thông báo chung",
    en: "General notification",
  },
  het_han_tin_dang: {
    vi: "Hết hạn tin đăng",
    en: "Expired posts",
  },
  thong_bao_gan_nhat: {
    vi: "Thông báo gần nhất",
    en: "Most recent notifications",
  },
  tin_cua_ban: {
    vi: "Tin của bạn",
    en: "Your post",
  },
  da_het_han: {
    vi: "đã hết hạn",
    en: "has expired",
  },
  sap_het_han: {
    vi: "sắp hết hạn",
    en: "is about to expire",
  },
  tin: {
    vi: "Tin",
    en: "Your post",
  },
  se_bi_het_han: {
    vi: "của bạn sẽ bị hết hạn trong vòng 1 ngày tới. Vui lòng xem tin tại đây.",
    en: "will expire within 1 day. Please view the post here.",
  },
  da_het_han_hien_thi: {
    vi: "của bạn đã hết hạn hiển thị trên các bảng tin của cộng đồng. Vui lòng xem tin tại đây.",
    en: "has expired and is no longer available in the community. Please view the post here.",
  },
  du_an: {
    vi: "Bài viết",
    en: "Your project",
  },
  nhan_duoc_yeu_cau_tam_gia_ho_tro: {
    vi: "của bạn nhận được yêu cầu tham gia hỗ trợ làm Tình nguyện viên từ Thành viên ",
    en: "has received a request for voluntary registration",
  },
  chia_se_tu_tinh_nguyen_vien: {
    vi: "Chia sẻ từ Tình nguyện viên",
    en: "Shares from Volunteer",
  },
  neu_ban_thay: {
    vi: "Nếu bạn thấy",
    en: "If you find",
  },
  phu_hop_dung_quen_lien_he: {
    vi: "phù hợp, đừng quên liên hệ với họ nhé.",
    en: "suitable, don't forget to contact the vonluteer.",
  },
  vui_long_cap_quyen_nhan_thong_bao: {
    vi: "Vui lòng cấp quyền nhận thông báo",
    en: "Unable to get permission to notify.",
  },
  tin_sap_het_han: {
    vi: "Tin sắp hết hạn",
    en: "Your post is about to expire",
  },
  tin_da_het_han: {
    vi: "Tin hết hạn",
    en: "Your post has expired",
  },
  tin_bi_bao_cao_vi_pham: {
    vi: "Tin bị báo cáo vi phạm",
    en: "Your post was reported",
  },
  giay_phep_thiet_lap_mang_xa_hoi: {
    vi: "Giấy phép thiết lập mạng xã hội trên mạng số 85/GP-BTTTT",
    en: "Social network license No. 85/GP-BTTTT",
  },
  giay_phep_thiet_lap_mang_xa_hoi_ngay_thang: {
    vi: "ngày 09 tháng 02 năm 2022 do Bộ Thông tin và Truyền thông cấp",
    en: "dated February 9, 2022, issued by the Ministry of Information and Communications    ",
  },
  thong_tin_dich_vu: {
    vi: "Thông tin dịch vụ",
    en: "Information",
  },
  dieu_khoan_chuong_trinh_footer: {
    vi: "ĐIỀU KHOẢN CHƯƠNG TRÌNH",
    en: "TERMS OF PROGRAM",
  },
  chinh_sach_du_lieu_footer: {
    vi: "CHÍNH SÁCH DỮ LIỆU",
    en: "DATA POLICY",
  },
  khong_chon_ngay_het_han: {
    vi: "Nếu không chọn ngày hết hạn, tin của bạn sẽ được hiển thị vĩnh viễn trên cộng đồng.",
    en: "If you don't choose an expiration date, your post will be permanently visible in the community.",
  },
  tiep_theo: {
    vi: "Tiếp theo",
    en: "Next",
  },
  dia_chi_khong_xac_thuc: {
    vi: "Địa chỉ không xác thực. Vui lòng chọn địa chỉ trên bản đồ.",
    en: "Address is not valid. Please choose another one on the map.",
  },
  ban_can_cap_quyen: {
    vi: "Bạn cần cấp quyền truy cập vị trí cho Kết nối yêu thương để sử dụng tính năng này.",
    en: "You need to grant Kết nối yêu thương access to Location services to detect your location.",
  },
  chon_thoi_han_hien_thi_tin: {
    vi: "Chọn thời hạn hiển thị tin",
    en: "Choose the period to display the post",
  },
  bo_qua_neu_ban_hien_thi_tin_vinh_vien: {
    vi: "Bỏ qua nếu bạn muốn hiển thị tin vĩnh viễn",
    en: "Skip if you want to display the post permanently",
  },
  ban_chi_duoc_nhap: {
    vi: "Bạn chỉ được nhập tối đa 250 kí tự ở phần Tự giới thiệu",
    en: "Your Introduce about yourself is allowed maximum of 250 characters",
  },
  ban_chua_nhap_dia_chi: {
    vi: "Bạn chưa nhập địa chỉ",
    en: "Address is required",
  },
  tai_hinh_len_that_bai: {
    vi: "Tải hình lên thất bại. Vui lòng thử lại sau!",
    en: "Photo fails to upload. Please try again later!",
  },
  cuon_len_dau_trang: {
    vi: "Cuộn lên đâù trang",
    en: "Scroll to top page",
  },
  danh_muc: {
    vi: "Danh mục",
    en: "Categories",
  },
};

export default Langs;
