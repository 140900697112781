import "@fontsource/nunito-sans/300.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import "../styles/globals.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Container, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import cookies from "next-cookies";
import Langs from "components/Const/Lang";

const theme = createTheme({
  typography: {
    fontFamily: "Nunito Sans",
    fontWeightMedium: 600,
  },
  palette: {
    primary: {
      main: "#FE9292",
      light: "#ffa8a8",
      dark: "#f17171",
      contrastText: "#fff",
    },
    borderWhite: {
      main: "#5596d2",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  button: {},
  props: {
    MuiOutlinedInput: { notched: true },
    MuiInputLabel: {
      shrink: true,
    },
  },
});
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
  const lang =
    cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", getScrollPosition);

    return () => {
      window.removeEventListener("scroll", getScrollPosition);
    };
  }, []);

  const getScrollPosition = () => {
    setScrollPosition(window.pageYOffset);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.cookie =
      "scrollTo={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
  };

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />

          {scrollPosition >= 500 && (
            <Container maxWidth="md">
              <Tooltip title={Langs?.cuon_len_dau_trang?.[lang]}>
                <IconButton
                  aria-label="scroll-top"
                  sx={{
                    color: "#f47376",
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zIndex: "999",
                  }}
                  onClick={scrollToTop}
                >
                  <ArrowCircleUpIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
            </Container>
          )}
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
